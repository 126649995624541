import React from 'react';
import {Widget} from '@typeform/embed-react'
import {getAmplitude} from "../analytics";

interface WithResponseId {
    responseId: string;
}

interface WithFormId {
    formId: string;
}

interface WithRef {
    ref: string;
}

interface WithHeight {
    height: number;
}

export type TypeFormWrapperProps = {
    formId: string,
    onFinish: (responseId: string, formExit: string) => void
}

let formExit = '';

function TypeFormWrapper({formId, onFinish}: TypeFormWrapperProps) {

    function logToAmplitudeTypeForm(event_name: string, properties: any) {
        getAmplitude().track('TYPEFORM_' + event_name, properties);
    }

    function logToAmplitudeTypeFormOnReady(event: WithFormId) {
        logToAmplitudeTypeForm('READY', {
            formId: event.formId
        });
    }

    function logToAmplitudeTypeFormOnStarted(event: WithFormId) {
        logToAmplitudeTypeForm('STARTED', {
            formId: event.formId
        });
    }

    function logToAmplitudeTypeFormOnSubmit(event: WithFormId & WithResponseId) {
        logToAmplitudeTypeForm('SUBMIT', {
            formId: event.formId,
            responseId: event.responseId
        });
        onFinish(event.responseId, formExit);
    }

    function logToAmplitudeTypeFormOnClose() {
        logToAmplitudeTypeForm('CLOSE', {});
    }

    function logToAmplitudeTypeFormOnQuestionChanged(event: WithFormId & WithRef) {
        formExit = event.ref;
        logToAmplitudeTypeForm('CHANGED', {
            formId: event.formId,
            ref: event.ref
        });
    }

    function logToAmplitudeTypeFormOnHeightChanged(event: WithFormId & WithRef & WithHeight) {
        logToAmplitudeTypeForm('HEIGHT_CHANGED', {
            formId: event.formId,
            ref: event.ref,
            height: event.height
        });
    }

    function logToAmplitudeTypeFormOnEndingButtonClicked(event: WithFormId) {
        logToAmplitudeTypeForm('ENDING_BUTTON_CLICKED', {
            formId: event.formId
        });
    }

    return (
        <div className="App">
            <Widget id={formId}
                    className={'typeform-widget'}
                    opacity={100}
                    medium={"snippet"}
                    autoFocus={true}
                    transitiveSearchParams={true}
                    inlineOnMobile={true}
                    iframeProps={{title: "Resony Anxiety Onboarding"}}
                    onReady={logToAmplitudeTypeFormOnReady}
                    onStarted={logToAmplitudeTypeFormOnStarted}
                    onSubmit={logToAmplitudeTypeFormOnSubmit}
                    onClose={logToAmplitudeTypeFormOnClose}
                    onQuestionChanged={logToAmplitudeTypeFormOnQuestionChanged}
                    onHeightChanged={logToAmplitudeTypeFormOnHeightChanged}
                    onEndingButtonClick={logToAmplitudeTypeFormOnEndingButtonClicked}
            />
        </div>
    );
}

export default TypeFormWrapper;


