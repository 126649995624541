import React, {useEffect, useState} from 'react';
import { useSearchParams } from "react-router-dom";
import './App.css';
import TypeFormWrapper from "./States/TypeFormWrapper";
import PaymentForm from "./States/PaymentForm";
import Download from "./States/Download";
import {getAmplitude} from "./analytics";
import NoAccess from "./States/NoAccess";

function App() {
    const stripePricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID ?? '';
    const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLIC_API_KEY ?? '';
    const formId = process.env.REACT_APP_TYPEFORM_ID ?? '';

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const state = searchParams.get('state');
        if (state) {
            setState(state);
        }
        const sessionId = searchParams.get('session-id');
        if (sessionId) {
            setPaymentSessionId(sessionId);
        }
    }, [searchParams])

    const [responseId, setResponseId] = useState('');
    const [state, setState] = useState('quiz');
    const [paymentSessionId, setPaymentSessionId] = useState('');

    const onFinishQuiz = (responseId: string, formExit: string): void => {
        setResponseId(responseId);
        getAmplitude().track('SHOW_PAYMENT_FORM', {formId: formId, responseId: responseId});
        if (formExit === 'payment') {
            setState(formExit);
        } else {
            setState('no-access');
        }
    }

    return (
        <div className="App">
            {state === 'quiz' && <TypeFormWrapper formId={formId}
                                                  onFinish={onFinishQuiz} />}
            {state === 'payment' && <PaymentForm clientReferenceId={`${formId}-${responseId}`}
                                                 pricingTableId={stripePricingTableId}
                                                 apiKey={stripeApiKey} />}
            {state === 'payment-finished' && <Download paymentSessionId={paymentSessionId} />}
            {state === 'no-access' && <NoAccess />}
        </div>
    );
}

export default App;


