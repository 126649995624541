import React from 'react';

export type DownloadProps = {
    paymentSessionId: string
}

function Download({paymentSessionId}: DownloadProps) {
    return (<>
        Please download
    </>)
}

export default Download;
