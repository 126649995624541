import React, {useEffect} from 'react';

export type PaymentFormProps = {
    clientReferenceId: string
    pricingTableId: string
    apiKey: string
}

function PaymentForm({clientReferenceId, pricingTableId, apiKey}: PaymentFormProps) {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return React.createElement("stripe-pricing-table", {
        "pricing-table-id": pricingTableId,
        "publishable-key": apiKey,
        "client-reference-id": clientReferenceId
    });
}

export default PaymentForm;
